import React from 'react'

const MonitorTaskAddContainer = Loader.loadModuleComponent('MonitorTasks', 'MonitorTaskAddContainer')

const PrivateNetMonitorTaskAdd = () => (
  <MonitorTaskAddContainer 
    libType={4}
    libLabel='布控对象'
    taskLabel='专网套件'
    taskType='101504'
    alarmThreshold={85}
    taskListModuleName='privateNetTaskView'
  />
)
 
export default PrivateNetMonitorTaskAdd;

